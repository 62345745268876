<script setup lang="ts">
import { useWithdrawalResult } from '../infrastructure/store';
import WithdrawalForm from './Form.vue';
import WithdrawalSuccessCard from './SuccessCard.vue';

const withdrawalResult = computed(() => useWithdrawalResult());
</script>

<template>
	<WithdrawalSuccessCard v-if="withdrawalResult?.type === 'success'" />

	<WithdrawalForm v-else />
</template>
