import { useApi } from '@/src/services/network';

export const useSendWithdrawal = () => {
	const api = useApi();

	return async (payload) => {
		return await api('/Transactions/withdraw/', {
			method: 'post',
			body: {
				paysystem: payload.withdrawalMethod.id,
				sum: payload.sum,
				account: payload.account,
			},
		});
	};
};
