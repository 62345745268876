import { withdrawalApi } from '../../infrastructure/api';
import {
	readWithdrawalMethod, resetWithdrawalMethod,
	saveWithdrawalResult,
} from '../../infrastructure/store';
import { notificationBus } from '~/src/features/app/infrastructure/bus';
import { useGetInvoices } from '~/src/features/invoices/core/application';

export const submitWithdrawal = () => {
	const sendWithdrawal = withdrawalApi.useSendWithdrawal();
	const triggerNotificationError = notificationBus.useOnTriggerNotificationError();
	const getInvoices = useGetInvoices();

	return async (data) => {
		const withdrawalMethod = readWithdrawalMethod();

		if (!withdrawalMethod) {
			return;
		}

		const result = await sendWithdrawal({
			withdrawalMethod,
			...data,
		});
		if (result.isRight()) {
			saveWithdrawalResult({
				type: 'success',
			});
			resetWithdrawalMethod();
			await getInvoices();
		}
		else {
			const errorMessage = result.value.message;
			saveWithdrawalResult({
				type: 'error',
				message: errorMessage,
			});
			triggerNotificationError(errorMessage);
		}
	};
};
