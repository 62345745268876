<script setup lang="ts">
import { BaseButton, BaseResultCard } from '@legacy-studio/library';
import { UiDarkBBButton } from '~/src/shared/ui';
import { openProfileModal } from '~/src/features/profile';
import { useOpenTerminalPage } from '~/src/shared/lib';
import { useSidebarStore } from '~/src/widgets/terminal/store';

const openTerminalPage = useOpenTerminalPage();
const drawerStore = useSidebarStore();
const openVerification = () => {
	openProfileModal();
	drawerStore.closeDrawer();
	openTerminalPage();
};
</script>

<template>
	<BaseResultCard
		type="info"
		text="Вывод средств невозможен. Завершите верификацию, чтобы продолжить"
	>
		<UiDarkBBButton
			size="xs"
			button-text="Верификация"
			@click="openVerification"
		/>

		<BaseButton
			color="alternative-dark"
			outline
			size="xs"
			button-text="Отменить"
			@click="drawerStore.goBack()"
		/>
	</BaseResultCard>
</template>
