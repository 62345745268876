<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseFetchWrapper,
	BaseTextField,
	BaseSelectButton,
	getTextFieldBinds,
	useAsyncState, formatAmount,
} from '@legacy-studio/library';
import { useGetPaymentMethodData } from '../../payment-method';
import { withdrawalSchema } from '../core/validation';
import type { WithdrawalForm } from '../core/types';
import {
	submitWithdrawal,
} from '../core/application';
import { UiDarkBBButton, UiInput } from '~/src/shared/ui';
import { useRealInvoice } from '~/src/features/invoices/infrastructure/store';
import { TraderDrawers } from '~/src/widgets/terminal/enum';
import { useDrawer } from '~/src/widgets/terminal/useDrawer';
import { usePaymentMethod } from '~/src/features/top-up/infrastructure/store';

const getPaymentMethodData = useGetPaymentMethodData();

const paymentMethod = usePaymentMethod();

const paymentItemData = computed(() => {
	if (paymentMethod.value) {
		return getPaymentMethodData(paymentMethod.value.id);
	}
	else {
		return null;
	}
});

const realInvoice = useRealInvoice();

const { defineField, handleSubmit, setFieldValue }
  = useForm<WithdrawalForm>({
  	validationSchema: withdrawalSchema,
  	initialValues: {
  		type: paymentItemData.value?.category.code,
  		account: '',
  		sum: '',
  	},
  });

watch(
	() => paymentItemData.value?.category.code,
	(val) => {
		if (val) {
			setFieldValue('type', val);
		}
	},
);

const [account, accountProps] = defineField('account', getTextFieldBinds);
const [sum, sumProps] = defineField('sum', getTextFieldBinds);

const { execute, isLoading, error } = useAsyncState(submitWithdrawal());
const { openDrawer } = useDrawer();
const onSubmit = handleSubmit(execute);
</script>

<template>
	<BaseFetchWrapper
		:pending="isLoading"
		:error="error"
		:hide-on-pending="true"
	>
		<form
			id="withdrawalFrom"
			class="flex flex-col gap-4"
			@submit.prevent="onSubmit"
		>
			<BaseSelectButton
				label="Метод"
				placeholder="Выберите метод вывода"
				size="regular"
				:value="paymentItemData?.name"
				@click="
					openDrawer(TraderDrawers.WithdrawalMethod)
				"
			/>
			<div
				v-if="paymentItemData"
				class="flex flex-col gap-2"
			>
				<BaseTextField
					v-if="paymentItemData?.category.code === 'crypto' || paymentItemData?.category.code === 'electroWallet'"
					v-model="account"
					v-bind="accountProps"
					:label="paymentItemData?.validation.name"
					size="regular"
					:placeholder="paymentItemData?.validation.placeholder"
				/>

				<BaseTextField
					v-else-if="paymentItemData?.category.code === 'bankCard'"
					v-model="account"
					v-bind="accountProps"
					:label="paymentItemData.validation.name"
					size="regular"
					:placeholder="paymentItemData?.validation.placeholder"
					maska="####-####-####-####"
				/>

				<span
					v-if="paymentItemData?.validation?.message"
					class="leading-tight text-sm font-normal text-gray-400"
				>
					{{ paymentItemData?.validation?.message }}
				</span>
			</div>

			<div class="flex flex-col gap-2">
				<UiInput
					v-model="sum"
					v-bind="sumProps"
					label="Сумма"
					placeholder="0.00"
					size="regular"
					right="USD"
					mask-type="currency"
				/>
				<span
					v-if="realInvoice?.realBalance"
					class="leading-tight text-sm font-normal text-gray-400"
				>
					Доступно для вывода <span class="text-white">{{ formatAmount(realInvoice?.realBalance) }} USD</span>
				</span>
			</div>

			<UiDarkBBButton
				v-if="paymentItemData"
				size="sm"
				type="submit"
				button-text="Вывод средств"
				form="withdrawalFrom"
			/>
		</form>
	</BaseFetchWrapper>
</template>
