<script setup lang="ts">
import { BaseIcon } from '@legacy-studio/library';
import { UiDarkBBButton } from '~/src/shared/ui';
import { saveWithdrawalResult } from '~/src/features/withdrawal/infrastructure/store';
import { useSidebarStore } from '~/src/widgets/terminal/store';
import { TraderDrawers } from '~/src/widgets/terminal/enum';

const onConfirm = () => {
	const drawerStore = useSidebarStore();
	saveWithdrawalResult(null);
	drawerStore.openDrawer(TraderDrawers.Finances);
};
</script>

<template>
	<div class="flex items-center p-4 flex-col gap-4 border border-gray-600 rounded-lg">
		<BaseIcon
			:name="'base--fill--check-circle'"
			class="text-[32px] text-green-400"
		/>
		<span class="text-lg font-semibold text-white text-center">
			Ваша заявка принята!
		</span>
		<span class="text-sm font-normal text-gray-400 text-center">
			Вывод средств на вашем типе
			<br>
			счета осуществляется в течение одного рабочего дня
		</span>
		<UiDarkBBButton
			size="xs"
			button-text="ОК"
			class="w-full"
			@click="onConfirm"
		/>
	</div>
</template>
